export default {
  /**
   * 获取着落页
   */
  getEntryLink:() => {
    var ua = navigator.userAgent;
    var isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isiOS) {
      return window.entryLink;
    }
    return encodeURIComponent(window.location.href.split("#")[0]);
  },
  debounce(fn, delay) {
    let delays = delay || 200;
    let timer;
    return function () {
      let th = this;
      let args = arguments;
      if(timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(function() {
        timer = null;
        fn.apply(th, args);
      }, delays)
    }
  }
}